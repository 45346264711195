// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  min-height: 100%;
  background-color: #f5f5f5;
  margin: 0;
}
.container {
  width: 100%;
  max-width: 1325px;
  margin: auto;
}
.header-button {
  color: lightgray;
}
.header-button:hover {
  color: white !important;
}
.ant-layout-sider-trigger {
  border-right: 1px solid #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAA;EACA,yBAAA;EACA,SAAA;AACF;AAEA;EACE,WAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAGA;EACE,gBAAA;AADF;AAIA;EACE,uBAAA;AAFF;AAKA;EACE,+BAAA;AAHF","sourcesContent":["html,\nbody {\n  min-height: 100%;\n  background-color: #f5f5f5;\n  margin: 0;\n}\n\n.container {\n  width: 100%;\n  max-width: 1325px;\n  margin: auto;\n}\n\n.header-button {\n  color: lightgray;\n}\n\n.header-button:hover {\n  color: white !important;\n}\n\n.ant-layout-sider-trigger {\n  border-right: 1px solid #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
